@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .print-full-w {
    max-width: 100%;
    width: 100%;
  }
  .print-center-content {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
  .print-bg-white {
    background-color: #fff !important;
  }
  .print-no-padding {
    padding: 0 !important;
  }
  .print-hide-shadow  {
    box-shadow: none !important;
  }
  .print-show-all {
    height: auto !important;
  }
  .print-avoid-breaks {
    page-break-inside: avoid;
  }
  .print-break-before {
    page-break-before: always;
  }
}
