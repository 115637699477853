.flow-slider input[type="range"] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  background: transparent; /* Otherwise white in Chrome */
  pointer-events: none; /* needed to avoid track pointer events?*/
}

.flow-slider input[type="range"]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

.flow-slider input[type="range"]::-ms-track {
  width: 100%;
  cursor: col-resize;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.flow-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  pointer-events: auto;
  height: 1rem;
  width: 1rem;
  border-radius: 1rem;
  background: #5bc4ec;
  cursor: pointer;
  margin-top: -0.5rem;
  box-shadow: 0px 0px 10px rgba(173, 173, 173, 0.178), 0px 0px 1px #0d0d0d31; /* Add cool effects to your sliders! */
}

.flow-slider input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
  pointer-events: auto;
  height: 1rem;
  width: 1rem;
  border: none;
  border-radius: 1rem;
  background: #5bc4ec;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(173, 173, 173, 0.178), 0px 0px 1px #0d0d0d31; /* Add cool effects to your sliders! */
}

.flow-slider input[type="range"]::-moz-focus-outer {
  border: 0;
}
